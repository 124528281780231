import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import Subtitle from '../../components/common/subtitle'
import Link from '../../components/common/link'
import PageContainer from '../../components/common/page-container'

export default (props) => (
    <Layout title="Front End Development">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
                <Title>Front End Development</Title>
            </TitleContainer>
        </BackgroundContainer>
        <PageContainer>
          <p>
            When it comes to front end development, there are lots of technical and business decisions to make. You may want your front end built with a server-side framework such as Laravel. 
            Another option is if an <Link href="/services/api-development">API</Link> has been developed, we can create a completely standalone single-page front end solution using a popular, well supported framework such as <Link href="/development/react-development">React</Link> or <Link href="/development/vue-development">Vue.js</Link>.
          </p>

          <p>
            As standard, we can provide you with a simple, easy to use interface to get you started. We can also work collaboratively with a UI/UX designer of your choice, or provide you with recommendations for designers we have previously worked with.
          </p>

          <p>
             packagedby can guide you in the decision making process, turning your front end vision into a finished project.
          </p>
          
        </PageContainer>
    </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "front-end-development.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
